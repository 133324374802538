<template>
  <div class="catalog">
    <b-container class="cards-container">
      <b-row class="my-3">
        <b-col class="d-flex justify-content-start">
          <div class="pages">
            <p>Found {{ filteredPaintings.length }} paintings</p>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            v-if="filters.activeFilters == 0"
            variant="outline-success"
            v-b-modal.filters-modal
          >
            <b-icon-filter></b-icon-filter> Filter
          </b-button>
          <b-button
            v-if="filters.activeFilters > 0"
            variant="success"
            v-b-modal.filters-modal
          >
            <b-icon-filter></b-icon-filter> Filter ({{ filters.activeFilters }})
          </b-button>
        </b-col>
      </b-row>
      <div class="no-results" v-if="filteredPaintings.length == 0">
        <b-icon-search font-scale="2" class="m-3"></b-icon-search>
        <p>
          Oops, looks like these filters did not give any results. Try
          removing/changing some filters
        </p>
      </div>
      <Cards-Table
        :items="filteredPaintings"
        :per-page="perPage"
        :current-page="currentPage"
      ></Cards-Table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </b-container>

    <b-modal
      id="filters-modal"
      :scrollable="true"
      title="Filter"
      size="lg"
      @ok="applyFilters"
    >
      <div class="filters">
        <div class="name my-3">
          <label for="medium-typeahead">Artist Name</label>
          <multiselect
            v-model="filters.artistName"
            :options="filterData.artistName"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="medium my-3">
          <label for="medium-typeahead">Medium</label>
          <multiselect
            v-model="filters.medium"
            :options="filterData.medium"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="category my-3">
          <label for="medium-typeahead">Categories</label>
          <multiselect
            v-model="filters.category"
            :options="filterData.category"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="size my-3">
          <label for="size">Size</label>
          <div class="params">
            <div class="size-param">
              <label for="height">height</label>
              <multiselect
                v-model="filters.height"
                :options="filterData.height"
                :multiple="true"
              ></multiselect>
            </div>
            <div class="size-param">
              <label for="height">width</label>
              <multiselect
                v-model="filters.width"
                :options="filterData.width"
                :multiple="true"
              ></multiselect>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="price my-3">
          <label for="medium-typeahead">Price</label>
          <multiselect
            v-model="filters.price"
            :options="filterData.price"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="availability my-3">
          <b-form-checkbox
            v-model="filters.hideSold"
            class="d-inline"
            name="check-button"
          ></b-form-checkbox
          ><span class="">&nbsp;Hide sold paintings</span>
        </div>
        <div class="availability my-3">
          <b-form-checkbox
            v-model="filters.isVerified"
            class="d-inline"
            name="check-button"
          ></b-form-checkbox
          ><span class=""
            >&nbsp;Show only verified artists marked with
            <b-img
              src="https://images.ctfassets.net/4n5fcbdr10ns/4xXtulBEXycNzkdmroA28j/1be93a7556d2ee421a3118edcb3b597e/pd-verified.png?h=20"
          /></span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CardsTable from "@/components/CardsTable.vue";
import { getData } from "../utils/sheetUtils";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "Catalog",
  props: {
    showId: String,
  },
  components: {
    CardsTable,
  },
  data: () => ({
    perPage: 12,
    currentPage: 1,
    paintings: [],
    filteredPaintings: [],
    filters: {
      medium: null,
      category: null,
      size: null,
      price: null,
      hideSold: false,
      isVerified: null,
      activeFilters: 0,
    },
    filterData: {
      artistName: [],
      medium: [],
      category: [],
      size: [],
      price: [],
    },
  }),
  computed: {
    rows() {
      return this.filteredPaintings.length;
    },
  },
  methods: {
    async populateShow(showId) {
      try {
        if (showId) {
          const response = await getData(showId);

          this.paintings = response.data;
          this.filterPaintings();
        }
      } catch (err) {
        console.log(err);
      }
    },
    setFilters(paintings) {
      console.log(paintings);
      const uniqueReducer = (unique, item) =>
        unique.includes(item) ? unique : [...unique, item];
      this.filterData = {
        artistName: paintings
          .map((painting) => painting.artistname)
          .reduce(uniqueReducer, []),
        medium: paintings
          .map((painting) => painting.medium)
          .reduce(uniqueReducer, []),
        category: paintings
          .map((painting) => painting.category)
          .reduce(uniqueReducer, []),
        height: paintings
          .map(
            (painting) =>
              painting.size.split("x") && painting.size.split("x")[0].trim()
          )
          .reduce(uniqueReducer, [])
          .sort(),
        width: paintings
          .map(
            (painting) =>
              painting.size.split("x") &&
              painting.size.split("x")[1] &&
              painting.size.split("x")[1].trim()
          )
          .reduce(uniqueReducer, [])
          .sort(),
        price: paintings
          .map((painting) => painting.price)
          .reduce(uniqueReducer, []),
      };
    },
    filterPaintings() {
      this.filters.activeFilters = 0;
      this.setFilters(this.paintings);
      if (
        this.filters.artistName ||
        this.filters.medium ||
        this.filters.price ||
        this.filters.category ||
        this.filters.height ||
        this.filters.width ||
        this.filters.hideSold ||
        this.filters.isVerified
      ) {
        let filteredPaintings = cloneDeep(this.paintings);

        // filter by medium
        if (this.filters.artistName && this.filters.artistName.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) =>
              this.filters.artistName.indexOf(painting.artistname) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // filter by medium
        if (this.filters.medium && this.filters.medium.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) => this.filters.medium.indexOf(painting.medium) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // filter by price
        if (this.filters.price && this.filters.price.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) =>
              this.filters.price &&
              this.filters.price.indexOf(painting.price) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // filter by category
        if (this.filters.category && this.filters.category.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) => this.filters.category.indexOf(painting.category) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // filter by size - height
        if (this.filters.height && this.filters.height.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) =>
              this.filters.height.indexOf(
                painting.size &&
                  painting.size.split("x")[0] &&
                  painting.size.split("x")[0].trim()
              ) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // filter by size - width
        if (this.filters.width && this.filters.width.length > 0) {
          filteredPaintings = filteredPaintings.filter(
            (painting) =>
              this.filters.width.indexOf(
                painting.size &&
                  painting.size.split("x")[1] &&
                  painting.size.split("x")[1].trim()
              ) >= 0
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // hide sold paintings
        if (this.filters.hideSold) {
          filteredPaintings = filteredPaintings.filter(
            (painting) => painting.availability === "Yes"
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        // show verified paintings
        if (this.filters.isVerified) {
          filteredPaintings = filteredPaintings.filter(
            (painting) =>
              painting.isVerified &&
              painting.isVerified.toLowerCase() === "verified"
          );
          this.filters.activeFilters++;
          // this.setFilters(filteredPaintings)
        }

        this.filteredPaintings = filteredPaintings;
      } else {
        this.filteredPaintings = this.paintings;
      }
    },
    clearFilters() {
      // this.filters = {
      //   medium: '',
      //   category: '',
      //   size: '',
      //   price: '',
      //   availability: true
      // };
      this.filterPaintings();
    },
    applyFilters() {
      this.filterPaintings();
    },
  },
  mounted() {
    this.populateShow(this.showId);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .size {
  display: flex;
  flex-direction: column;
} 

.size>label {
  flex: 0 0 100%; 
}
*/
.filters {
  width: 80%;
}

.size-param {
  float: left;
  margin-right: 10px;
  width: 45%;
}
</style>
