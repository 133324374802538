<template>
  <div>
    <b-row>
      <b-col
        v-for="item in currentPageItems"
        :key="item.message"
        xs="12"
        sm="6"
        md="4"
        @click="showModal(item)"
      >
        <Card :item="item"></Card>
      </b-col>
    </b-row>
    <b-modal id="detail-modal" size="xl" hide-header>
      <b-button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="hideModal()"
      >
      </b-button>
      <img
        class="dynamic-image painting"
        :src="modalItem.bigimg"
        :alt="modalItem.title"
      />
      <div class="details-list px-3">
        <div class="title"><label>Code:</label> {{ modalItem.code }}</div>
        <div class="title">
          <label>Artist:</label> {{ modalItem.artistname }}
        </div>
        <div class="title"><label>Title:</label> {{ modalItem.title }}</div>
        <div class="details">
          <label>Size (in inches):</label> {{ modalItem.size }}
        </div>
        <div class="description">
          <label>Medium:</label> {{ modalItem.medium }}
        </div>
        <div class="Price"><label>Price:</label> {{ modalItem.price }}</div>
      </div>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="md"
          variant="outline-success"
          target="_new"
          :href="`mailto:info@pradarshak.in?subject=Enquiry | ${collectionName}&body=Hi I'm Interested in ${modalItem.code} from the ${collectionType}`"
        >
          Enquire via Email
        </b-button>
        <b-button
          size="md"
          variant="outline-success"
          target="_new"
          :href="`https://wa.me/+919920386641?text=Hi I'm Interested in ${
            modalItem.code
          } from the ${collectionType} ${
            (collectionName && `,${collectionName}`) || ''
          }`"
        >
          Enquire via WhatsApp
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";

export default {
  components: {
    Card,
  },
  name: "CardsTable",
  props: ["items", "perPage", "currentPage"],
  data: () => ({
    currentPageItems: [],
    modalItem: {},
    collectionType: "affordable art collection",
    collectionName: "Ekta - Hearts meet with Art",
  }),
  computed: {},
  methods: {
    paginate: function () {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    showModal(item) {
      this.modalItem = item;
      this.$bvModal.show("detail-modal");
    },
    hideModal() {
      this.$bvModal.hide("detail-modal");
    },
  },
  mounted() {
    if (this.$route.query.collectionType) {
      // console.log("Set: Collection Type: ", this.$route.query.collectionType)
      this.collectionType = this.$route.query.collectionType;
    }

    if (this.$route.query.collectionName) {
      // console.log("Set: Collection Name: ", this.$route.query.collectionName)
      this.collectionName = this.$route.query.collectionName;
      if (this.collectionName === " ") {
        this.collectionName = undefined;
      }
      console.log({ collectionName: this.collectionName });
    }
    this.currentPageItems = this.paginate();
  },
  watch: {
    items: function (newItems) {
      this.items = newItems;
      this.currentPageItems = this.paginate();
    },
    currentPage: function (newPage) {
      this.currentPage = newPage;
      this.currentPageItems = this.paginate();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.modal-dialog {
  display: flex;
  height: 75vh;
  max-height: 800px;
}

.dynamic-image {
  object-fit: contain;
  max-width: 80%;
}

.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modal-body button.btn-close {
  position: absolute;
  right: 0px;
  top: 0;
  transform: translate(50%, -50%);
  background-color: white;
  opacity: 0.75;
  border: 1px solid #ededed;
}

@media screen and (max-width: 1024px) {
  .modal-dialog {
    display: flex;
    height: auto;
  }
  .modal-content {
    height: 100%;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .dynamic-image {
    max-height: 600px;
    object-fit: contain;
  }
  .details-list {
    padding: 1rem 0 !important;
  }
}

.modal-header button.close {
  border: none;
  background: none;
  font-size: 28px;
}

.modal-footer {
  justify-content: center;
}
</style>
