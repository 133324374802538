

import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { connectFunctionsEmulator } from 'firebase/functions';
import config from '../../firebase.config';

const app = initializeApp(config);
const functions = getFunctions(app)
// connectFunctionsEmulator(functions, "localhost", 5001);

const getSpreadsheetData = httpsCallable(functions, 'getSpreadsheetData');


const getData = async (sheetId) => {
    return getSpreadsheetData({ id: sheetId });
};

export { getData };

// import axios from "axios";
// const getData = async (sheetId) => {
//     const response = await fetch(`/api/spreadsheet?id=${sheetId}`);
//     const data = await response.json();
//     return data;
// };

// export { getData };