<template>
  <b-container class="py-5">
    <b-row>
      <b-col md="4" xs="12">
        <b-card
          title="EKTA - hearts meet with art"
          img-src="https://images.ctfassets.net/4n5fcbdr10ns/5ruGDjHBpmoqhkfDpic2y5/0d801a57fda1a89ae0a405d765302786/Ekta_-_FB_Cover__4_.gif"
          img-alt="Ekta"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text class="text-align-left">
            <p>Genres range from: Still Life, Semi-Abstract Figurative, Compositions, Abstracts to Landscapes, Citiscapes and Seascapes as well as Drawings and Graphic Prints.</p>
            <p>Subjects: Flowers, Horses, Birds, Green fields, Puppets, Couples, Decorative, Religious icons etc.</p>
            <p>Price range: up to 15000/- only.</p>
          </b-card-text>
          <router-link to="/show/1ELHaoNZEm2KMpZVtiDD8ePU8vNpPn3yVfaZz46w0w5w" tag="b-button">View</router-link>
        </b-card>
      </b-col>
      <b-col md="4" xs="12">
        <b-card
          title="Expressions I"
          img-src="https://images.ctfassets.net/4n5fcbdr10ns/prPrwDxbWXrGRPj1uEu3t/ee8e8b364eb8c45b0d85b485e60a989d/Bhaav_Group_Show_13__3_.gif"
          img-alt="Expressions I"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text class="text-align-left">
            <p>Subjects: Abstracts</p>
          </b-card-text>
          <router-link to="/show/1Vc47hCU9y1ZTMdf42zYahdnzBY1cfA-BpXdQ2nPJZq4?collectionName=Expressions%201%20Collection&collectionType=Abstract%20Art%20collection" tag="b-button">View</router-link>
        </b-card>
      </b-col>
      <b-col md="4" xs="12">
        <b-card
          title="AUM 2.0"
          img-src="https://images.ctfassets.net/4n5fcbdr10ns/2peoRLTRK1xKoSATyK0xXv/a46b3c73a2a90a161f22a3d39df1e97b/Ekta_-_FB_Cover.png"
          img-alt="AUM 2.0"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text class="text-align-left">
            <p>Subjects: Abstracts</p>
          </b-card-text>
          <router-link to="/show/1VU7YT58FuEJTkkWQ-5KUSbQ7ZvNW71LDUSzyFWwDd4k?collectionName=AUM%202.0%20Collection&collectionType=Affordable,%20unique%20and%20memorable%20collection" tag="b-button">View</router-link>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
