<template>
  <div>
    <b-card>
      <div
        class="image-container d-flex justify-content-center align-items-center my-2"
      >
        <b-img
          class="card-image img-fluid"
          :src="item.bigimg"
          :alt="item.title"
        ></b-img>
      </div>

      <div class="floating-tags d-flex flex-direction-row">
        <div class="pd-verified" v-if="item.isVerified">
          <b-img
            src="https://images.ctfassets.net/4n5fcbdr10ns/4xXtulBEXycNzkdmroA28j/1be93a7556d2ee421a3118edcb3b597e/pd-verified.png?h=20"
          />
        </div>
        <div class="sold" v-if="item.availability == 'No'">
          <b-badge pill variant="danger">Sold</b-badge>
        </div>
      </div>

      <div class="share-holder" v-if="canShare">
        <b-icon-share
          class="share-icon"
          @click="share(item, $event)"
        ></b-icon-share>
      </div>
      <b-card-text class="card-content">
        <div class="content">
          <p class="code">{{ item.code }}</p>
          <div class="title">{{ item.title }}</div>
          <p class="name">{{ item.artistname }}</p>
          <div class="description">{{ item.medium }}</div>
          <div class="details">{{ item.size }}</div>
        </div>
      </b-card-text>
      <div class="footer-content">
        <div class="price"><label>₹</label> {{ item.price }}</div>
        <div class="button-container">
          <b-button
            v-b-tooltip.hover
            title="Email"
            class="m-2"
            size="md"
            variant="outline-success"
            target="_new"
            :href="mailTo"
            @click="fireGtagEmail(item)"
          >
            <b-icon-envelope></b-icon-envelope>
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Whatsapp"
            class="m-2"
            size="md"
            variant="outline-success"
            target="_new"
            :href="whatsappMessage"
            @click="fireGtagWA(item)"
          >
            <b-icon-chat></b-icon-chat>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ["item"],
  data: () => ({
    collectionType: "affordable art collection",
    collectionName: "Ekta - Hearts meet with Art",
  }),
  computed: {
    whatsappMessage() {
      return `https://wa.me/+919920386641?text=Hi I'm Interested in ${this.item.code} from the ${this.collectionType}, ${this.collectionName}`;
    },
    mailTo() {
      return `mailto:info@pradarshak.in?subject=Enquiry | ${this.collectionName}&body=Hi I'm Interested in ${this.item.code} from the ${this.collectionType}`;
    },
    canShare() {
      return window.navigator.share;
    },
  },
  methods: {
    async createFile(url, type) {
      if (typeof window === "undefined") return; // make sure we are in the browser
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type || "video/quicktime",
      };
      return new File([data], url, metadata);
    },
    async share(item, event) {
      event.stopPropagation();
      if (navigator.share) {
        const response = await fetch(item.bigimg);
        const blob = await response.blob();
        const file = new File([blob], `${item.code}.jpg`, { type: blob.type });

        navigator
          .share({
            files: [file],
            text: `
            Check out this work from the ${this.collectionName} on Gallery Pradarshak
            Code: ${item.code}
            Title: ${item.title}
            Artist Name: ${item.artistname}
            Size(in inches): ${item.size}
            Medium: ${item.medium}
            Price: ${item.price}
          `,
            url: window.location.href,
          })
          .then(() => console.log("Share was successful."))
          .catch((error) => console.log("Sharing failed", error));
      } else {
        console.log(`Your system doesn't support sharing files.`);
      }
    },
    fireGtagWA(item) {
      console.log("Fire gTag");
      this.$gtm.trackEvent({
        event: "conversion", // Event type [default = 'interaction'] (Optional)
        category: "Contact",
        send_to: "AW-1017454181/r-q0CJrTvcUCEOW8lOUD",
        currency: "INR",
        value: Number(item.price?.replace(/[^0-9.-]+/g, "")) || 1,
        label: "Contact via whatsapp",
        noninteraction: false, // Optional
      });
    },
    fireGtagEmail(item) {
      console.log("Fire gTag");
      this.$gtm.reportConversion({
        event: "conversion", // Event type [default = 'interaction'] (Optional)
        category: "Contact",
        send_to: "AW-1017454181/Zmq9CJvNvcUCEOW8lOUD",
        currency: "INR",
        label: "Contact via email",
        value: Number(item.price?.replace(/[^0-9.-]+/g, "")) || 1,
        noninteraction: false, // Optional
      });
    },
  },
  async mounted() {
    if (this.$route.query.collectionType) {
      // console.log("Set: Collection Type: ", this.$route.query.collectionType)
      this.collectionType = this.$route.query.collectionType;
    }

    if (this.$route.query.collectionName) {
      // console.log("Set: Collection Name: ", this.$route.query.collectionName)
      this.collectionName = this.$route.query.collectionName;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  padding-top: 300px;
}

.image-container {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image-container img {
  max-width: 100%;
  max-height: 300px;
}
.card {
  margin-bottom: 15px;
}

.card-body {
  text-align: left;
}

.floating-tags {
  position: absolute;
  top: 10px;
  left: 10px;
}

.share-holder {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  border-radius: 20px;
  box-shadow: 2px 2px 12px 0px #888888;
  width: 30px;
  padding: 5px;
  text-align: center;
}

.pd-verified {
  padding: 0 5px;
  height: 30px;
  background: white;
  border: 1px solid white;
  border-radius: 30px;
  opacity: 0.95;
  margin-right: 10px;
}

.sold {
  background: red;
  border-radius: 30px;
  width: 70px;
  height: 30px;
  text-align: center;
  font-size: 18px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-weight: bold;
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.name {
  font-weight: bold;
  color: rgb(68, 68, 68);
  font-size: 14px;
}

.card-content,
.card-content p {
  margin-bottom: 0;
}
</style>
