<template>
  <div class="show">
    <Catalog :showId="showId"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Catalog from '@/components/Catalog.vue'

export default {
  name: 'Home',
  components: {
    Catalog
  },
  data: function() {
    return {
      showId: ''
    }
  },
  beforeMount() {
    this.showId = this.$route.params.id
  }
}
</script>
